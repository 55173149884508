<template>
    <div class="login-page">
        <div class="login-container">
            <p class="item-title">登录导购系统</p>
            <div class="item-input">
                <van-icon name="friends"/>
                <input v-model="loginForm.name" placeholder="请输入您的账号" type="text">
            </div>
            <div class="item-input">
                <van-icon name="lock"/>
                <input v-model="loginForm.password" placeholder="请输入您的密码" type="password">
            </div>
            <div class="item-checkbox">
                <van-checkbox v-model="isRememberPassword" icon-size="0.11rem" shape="square">记住密码</van-checkbox>
            </div>
            <van-button block class="item-button" color="#F28246" type="primary" @click="login()">登&nbsp;&nbsp;&nbsp;&nbsp;录</van-button>
        </div>
        <div class="logo">
            <img draggable="false" src="../../assets/img/login/logo.png">
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入本地存储
import {setCookie} from "@/utils/storage"
// 引入接口
import {LOGIN} from "@/api/dataProvider.js"

export default {
    name: "Login",
    mixins: [mixins_config],
    data() {
        return {
            loginForm: {
                name: "",
                password: ""
            },
            isRememberPassword: true
        };
    },
    mounted() {
    },
    methods: {
        login() {
            let that = this
            if (!that.loginForm.name) {
                that.$notify({type: "danger", message: "请输入用户名！"});
                return false
            }
            if (!that.loginForm.password) {
                that.$notify({type: "danger", message: "请输入密码！"});
                return false
            }
            LOGIN({
                username: that.loginForm.name,
                password: that.loginForm.password,
                tokenName: "web"
            }, function (res) {
                console.log(res)
                if (res.data.code == 0) {
                    setCookie("token", res.data.data.token, 2592000)
                    that.app.userData = res.data.data.user
                    that.$notify({
                            type: "success",
                            message: "登录成功",
                            onClose() {
                                that.$router.push({name: "Home"})
                            }
                        }
                    );
                } else {
                    that.$notify({type: "danger", message: "登录失败，请检查后重试！"});
                }
            })
        }
    }
}
</script>

<style lang="scss">
.login-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 150px 0 0 0;
    background: url("../../assets/img/login/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .login-container {
        position: relative;
        width: 333px;
        height: 266px;
        margin: 0 auto 0 auto;
        background: #fff;
        border-radius: 7px;
        box-shadow: 0px 1px 2px rgba(210, 73, 0, 0.46);
        z-index: 999;

        .item-title {
            padding: 14px 0 24px 0;
            text-align: center;
            font-size: 21px;
            color: #F28246;
        }

        .item-input {
            width: 306px;
            margin: 14px auto 0 auto;
            border: 1px solid #EEEEEE;
            border-radius: 2px;

            i {
                vertical-align: middle;
                padding: 0 7px;
                font-size: 16px;
                color: #999999
            }

            input {
                vertical-align: middle;
                width: 274px;
                line-height: 30px;
                font-size: 10px;
                color: #333;
                border: none;
            }
        }

        .item-checkbox {
            width: 306px;
            margin: 14px auto 0 auto;

            .van-checkbox {
                .van-checkbox__label {
                    font-size: 9px;
                    color: #999999;
                }
            }
        }

        .item-button {
            width: 306px;
            margin: 14px auto 0 auto;
        }
    }

    .logo {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        text-align: center;

        img {
            width: 306px;
        }
    }
}
</style>